<script setup>

import Icon from "@/Icons/jobs-icons.vue";
import {computed, ref, watch} from "vue";
import moment from "moment";

const props = defineProps({
    post: Object,
    initialLikeCounts: Number,
    initialLikeTypes: Array,
});
const likeTypes = ref(props.initialLikeTypes);
function getNumber(post) {
    if (!isAtLeastThreeHoursOld(post?.created_at)) {
        return 0;
    }
    const hashed = xorshift(post.id);
    const min = 20;
    const max = 120;
    const range = max - min + 1;
    likeTypes.value = ['bell', 'like', 'love', 'rocket'];
    return min + (hashed % range);
}
function isAtLeastThreeHoursOld(date) {
    const givenDate = moment(date);
    const currentDate = moment();
    const diffInHours = currentDate.diff(givenDate, "hours");
    return diffInHours >= 3;
}

function xorshift(seed) {
    let x = seed ^ (seed << 11);
    x = x ^ (x >> 19);
    x = x ^ (x << 8);
    return x >>> 0; // Ensure the result is positive
}
const baseLikeCounts = getNumber(props.post);
const likeCounts = ref(baseLikeCounts + props.initialLikeCounts);

watch(() => props.initialLikeCounts, (newVal) => {
    likeCounts.value = baseLikeCounts + newVal;
});
watch(() => props.initialLikeTypes, (newVal) => {
    if (!isAtLeastThreeHoursOld(props.post?.created_at)) {
        likeTypes.value = [...newVal];
    }
});

</script>

<template>
    <div class="w-full like-container">
        <div class="flex items-center w-full gap-8">
            <div class="flex items-center -space-x-1 text-xs text-main group/wrapper">
                <div class="relative">
                    <div class="flex -space-x-2"
                    >
                        <div v-if="likeTypes.includes('bell')"
                            class="flex items-center justify-center w-4 h-4 p-0.5 overflow-hidden border bg-[#535EC7] border-white rounded-full"
                        >
                            <Icon type="bell" />
                        </div>
                        <div v-if="likeTypes.includes('like')"
                            class="flex items-center justify-center w-4 h-4 p-0.5 overflow-hidden border bg-[#FF9851] border-white rounded-full"
                        >
                            <Icon type="like-alt" />
                        </div>
                        <div v-if="likeTypes.includes('love')"
                            class="flex items-center justify-center w-4 h-4 p-0.5 overflow-hidden border bg-[#FF6161] border-white rounded-full"
                        >
                            <Icon type="love" />
                        </div>
                        <div  v-if="likeTypes.includes('rocket')"
                            class="flex items-center justify-center w-4 h-4 p-0.5 border bg-[#618E58] border-white rounded-full"
                        >
                            <Icon type="rocket" />
                        </div>
                    </div>
                </div>
                <div
                    class="inline-flex items-center justify-center w-8 h-8 font-medium text-primary"
                >
                    {{ likeCounts || '' }}
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>
